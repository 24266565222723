<template>
  <div class="About">
    <h1>About</h1>
    <div class="about d-flex flex-row flex-wrap">
        <div id="profile">
            <img :src="require(`@/assets/${imgsrc2}`)" />
            <!--img :src="require(`@/assets/${imgsrc1}`)" /-->
        </div>
        <div id="bio">
            <p> Hello! <br />
            I am a freelance digital illustrator and graphics artist. 
            </p>
            <p> I create illustrations, portraits, graphical assets, as well as web development and scripting. <br />
            (And I do it all passionately with my libre open-source ecosystem: Krita, Blender, Inkscape, etc on Linux!).
                <br /> 
                <!--span>(such as this website. source code here: <a href="#">gitlab</a>)</span-->
            </p>
            <p>
            Currently, I am available for freelance commissions, contracts, or relocation for studio work.  
            <br />
            <br />
            Thanks for visiting &lt;3
            </p>
            <div id="socials">
                <strong>find me here: </strong>
                <a href="https://twitter.com/soulrot13"><i class="fab fa-twitter" /></a> 
                <a href="https://artstation.com/soulrot13"><i class="fab fa-artstation" /></a> 
                <a href="https://gitlab.com/#"><i class="fab fa-gitlab" /></a>
                <br />
                <strong>contact me: {{ prefix }}@{{ suffix }}</strong>
            </div>
        </div>
    </div>
  </div>
</template>
<style>
div{
    
}
#profile{
    background: linear-gradient(0deg, rgba(0,0,159,1) 1%, rgba(0,212,255,0) 90%);
    background: linear-gradient(0deg, rgb(0, 0, 0) 30%, rgba(0,212,255,0) 90%);
    background:none;
    width:30vw;

}
#profile img{
    width:100%;
}
#profile img:last-of-type>null{
    padding-top:0em;
    position:relative;
    bottom:20em;
    width:50%;
}
#bio{
    width:30vw;
    text-align:left;
}
#socials{
    text-shadow:1px 1px 4px pink;
}
i{
    margin: 0px .4em;
}
.About .d-flex>div{
    margin:3em;
}
.About{
    width:85vw;
    
    margin:0px auto ;
    margin-top:2em;
    justify-content:center;

}
a{
    color:pink;
    color:#C44AAF;
    color:#333;
}



.About .d-flex>div{
    margin:.5em;
}
.About{
    margin:0px auto;
}
#bio{
    width:50vw;
    margin:0px auto;
    padding:2vw;
    margin-top:3em;
}
#profile{
    margin:0px auto;
    min-width:250px;
}

    

@media screen and (max-width:800px) {
    .About{ 
        width:95vw
    }
    #bio{
        width:60vw;
    }
}



</style>
<script>

// @ is an alias to /src

export default {
  name: 'About',
  data() {
      return {
          prefix: "sriver4",
          suffix: "pm.me",
          //imgsrc: "bluepixelme_grey.png",
          imgsrc1: "bluepixelme2.png",
          //imgsrc2: "desk_2.png"
          imgsrc2: "desk_ghost3.gif"
      }

  },
  components: {
  }, 
  mounted() {
      

  }

}
</script>
